import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { EmailButton, PhoneButton } from "../shared/button/button"
import { LandPurchaseStyled } from "./landPurchaseStyle"
import img from "../../images/z-7/z7e-map2.jpg"
import ContactForm from "../shared/contactForm/contactForm"
import { ContactStyled } from "../contact/contactStyle"
import { injectIntl } from "gatsby-plugin-intl"

const LandPurchase = ({ intl }) => (
  <ContactStyled>
    <LandPurchaseStyled>
      <Container>
        <Row>
          <Col xs={12} lg={6} className={"first-section"}>
            <h1>{intl.formatMessage({ id: "landPurchase.title" })}</h1>
            <p>
              {intl.formatMessage({ id: "landPurchase.content.front.bold" })}
            </p>
            <p>{intl.formatMessage({ id: "landPurchase.content" })}</p>
            <div className="second-section">
              <p>
                {intl.formatMessage({ id: "landPurchase.content.back.bold" })}
              </p>
            </div>
            <div style={{ margin: "0 -15px" }}>
              <Row>
                <Col lg={6} md={7} sm={7} xs={12}>
                  <div className="second-section">
                    <p />
                    <p className={"subtitle"}>WILLBUD</p>
                    <p>
                      {intl.formatMessage({ id: "headquater.adress.street" })}
                    </p>
                    <p>
                      {intl.formatMessage({ id: "headquater.adress.post" })}
                    </p>
                    <p className="subtitle" style={{ marginTop: "20px" }}>
                      {intl.formatMessage({
                        id: "headquater.adress.hours.title",
                      })}
                    </p>
                    <p>
                      {intl.formatMessage({ id: "headquater.adress.hours" })}
                    </p>

                    <div className="buttons d-block ">
                      <PhoneButton innerText={`+48 572 36 12 13`} />
                      <EmailButton innerText={"grunty@willbud.pl"} />
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={5} sm={5} xs={12}>
                  <div className={`map-section`}>
                    <img
                      style={{ maxWidth: "100%" }}
                      src={img}
                      alt={`Plan miasta`}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            lg={6}
            className={
              "d-none d-lg-block position-static form-container-parent"
            }
          >
            <div className="bg-form" />
            <ContactForm landPurchaseView={true} />
          </Col>
        </Row>
      </Container>
      <div className="bg-gray contact-form-mobile d-block d-lg-none">
        <Container>
          <ContactForm landPurchaseView={true} />
        </Container>
      </div>
    </LandPurchaseStyled>
  </ContactStyled>
)

export default injectIntl(LandPurchase)
