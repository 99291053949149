import React from "react"
import Layout from "../../components/layout"
import LandPurchase from "../../components/landPurchase/landPurchase"
import Helmet from "react-helmet"

const LandPurchasePage = () => (
  <>
    <Helmet>
      <title>WILLBUD | Zakup gruntów</title>
    </Helmet>
    <Layout>
      <LandPurchase />
    </Layout>
  </>
)

export default LandPurchasePage
