import styled from "styled-components"
export const LandPurchaseStyled = styled.div`
  width: 100%;
  & > div.container {
    position: relative;
  }
  .bg-gray {
    background-color: #f8f8f8;
  }
  h1 {
    font-weight: 500;
    font-size: 37px;
    margin-bottom: 20px;
  }
  .first-section {
    padding: 0;
    padding-bottom: 40px;
    p {
      font-size: 20px;
      font-weight: 300;
      margin: 0;
    }
    p:nth-of-type(1) {
      font-weight: 500;
      margin-bottom: 10px;
    }
    p:nth-of-type(2) {
      padding: 0;
      margin-bottom: 25px;
    }
  }
  .second-section {
    width: 100%;
    p {
      font-size: 20px;
      font-weight: 300;
    }
    p:nth-of-type(1) {
      font-weight: 500;
      margin-bottom: 40px;
    }
    p.subtitle {
      padding: 0;
      text-transform: uppercase;
      font-weight: 500;
      white-space: nowrap;
    }
    p.subtitle:nth-last-of-type(2) {
      font-size: 16px;
    }
    .buttons {
      padding-top: 30px;
      a:first-child button {
        margin-bottom: 14px;
      }
    }
  }
  .contact-form-mobile {
  } 
    
    .map-section {
        padding: 20px 0 100px;
    }

  @media (min-width: 1200px) {
    //padding-top: 60px;
    & > div.container {
      position: static;
    }
    h1 {
      font-size: 48px;
      margin-bottom: 40px;
    }
    .first-section {
      padding: 0;
      margin-bottom: 25px;
      padding-right: 80px;
      p {
        font-weight: 300;
        padding: 0;
      }
      p:nth-of-type(1) {
        margin-bottom: 25px;
        font-weight: 500;
        padding: 0;
      }
    }
    .bg-gray {
      background-color: transparent;
    }
    .land-image {
      position: absolute;
      right: 150px;
      bottom: -250px;
    }
    .button-desktop {
      padding-top: 40px;
      a {
        display: inline-block;
      }
    }
    .second-section {
      padding-top: 0;
      padding-bottom: 20px;
      padding-right: 80px;
      .subtitle {
        margin-bottom: 10px;
      }
      p:nth-of-type(2) {
        width: 38%;
      }
    }
     
  }
    @media (min-width: 1200px) and (max-width: 1440px) {
        .map-section {
            padding-left: 20px;
        }
    }
    
`
